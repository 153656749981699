@tailwind base;
@tailwind components;
@layer utilities {
    .blink {
        animation: blinker 1s linear infinite;
        color: red;
        font-size: 15px;
        font-weight: bold;
        font-family: sans-serif;
        margin-left: 5px;
    }
    @keyframes blinker {
        50% {
            opacity: 0;
        }
    }
    .bg-title-screen {
        background: linear-gradient( 180deg, rgba(246, 135, 179, 0.2) 0%, rgba(255, 255, 255, 0) 23.4%, rgba(255, 255, 255, 0) 74.81%, rgba(246, 135, 179, 0.1) 100%);
    }
    .bg-main-btn {
        background: linear-gradient(88.5deg, #f8a1c3 1.91%, #de6292 97.02%);
    }
    .bg-footer {
        background: linear-gradient(90.32deg, #0d0d0d 0%, #2c384f 100%);
    }
    .text-gradient {
        background-clip: text;
        -webkit-background-clip: text;
        color: transparent;
        background-image: linear-gradient(180deg, #b2bdd6 0%, #626d86 100%);
    }
    .bg-pattern {
        background-image: image-set(url("/assets/pattern.png?as=webp"));
        background-image: url("/assets/pattern.png");
    }
}


/* noto-sans-thai-300 - thai */



@font-face {
    font-family: "Inter var";
    font-weight: "100 900";
    font-style: normal;
    font-display: swap;
    src: url("/assets/fonts/Inter-roman.var.woff2?3.13") format("woff2");
}

@font-face {
    font-family: "Inter var";
    font-weight: "100 900";
    font-style: italic;
    font-display: swap;
    src: url("/assets/fonts/Inter-italic.var.woff2?3.13") format("woff2");
}

@font-face {
    font-family: "Sukhumvit Set";
    font-display: swap;
    src: local("Sukhumvit Set");
    unicode-range: "U+0E01-0E5B, U+200C-200D, U+25CC";
}

@tailwind utilities;